<template>
    <ul class="filter-list">
        <li v-for="(item, index) in filterArray"
            :key="index">
            <a href="javascript:;"
                :class="{
                    'selected-filter': filter_value == item.value
                }"
                @click="handleClick(item.value)">
                {{item.label}}
            </a>
        </li>
    </ul>
</template>

<script>
    export default {
        data() {
            return {
                filter_value: "",
            }
        },
        props: {
            filterArray: Array
        },
        methods: {
            handleClick(val){
                this.filter_value = val
            }
        },
    }
</script>

<style scoped>
.filter-list{
    display: flex;
    flex-wrap: wrap;
}
.filter-list > li{
    margin-bottom: 10px;
}
.filter-list a{
    display: block;
    padding: 2px 16px;
    border-radius: 30px;
    color: #303133;
}
.filter-list a.selected-filter{
    background-color: #fca29a;
    color: #fff;
}
</style>